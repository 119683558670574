<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5 v-t="`Filters`" />
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          lg="6"
          md="12"
          class="mb-md-0 mb-2 pb-1"
        >
          <i-input
            v-model="localDiscountTypeFilter"
            type="select"
            :title="$t('modules.coupons.inputs.discount_type')"
            :placeholder="$t('modules.coupons.inputs.commission_status:placeholder')"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="discountTypeOptions"
            :reduce="val => val.value"
            class="invoice-filter-select mb-0"
            @input="(val) => $emit('update:discountTypeFilter', val)"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </i-input>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          md="12"
          class="mb-md-0 mb-2 pb-1"
        >
          <i-input
            v-model="localDiscountSegmentFilter"
            type="select"
            :title="$t('modules.coupons.inputs.discount_segment')"
            :placeholder="$t('modules.coupons.inputs.commission_status:placeholder')"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="discountSegmentOptions"
            :reduce="val => val.value"
            class="invoice-filter-select mb-0"
            @input="(val) => $emit('update:discountSegmentFilter', val)"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </i-input>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          md="12"
          class="mb-md-0 mb-2"
        >
          <i-input
            v-model="localDiscountUsabilityFilter"
            type="select"
            :title="$t('modules.coupons.columns.discount_usability')"
            :placeholder="$t('modules.coupons.inputs.commission_status:placeholder')"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="discountUsabilityOptions"
            :reduce="val => val.value"
            class="invoice-filter-select mb-0"
            @input="(val) => $emit('update:discountUsabilityFilter', val)"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </i-input>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          md="12"
          class="mb-md-0 mb-2"
        >
          <i-input
            v-model="localStatusFilter"
            type="select"
            :title="$t('Status')"
            :placeholder="$t('modules.coupons.inputs.commission_status:placeholder')"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="booleanStatusOptions"
            :reduce="val => val.value"
            class="invoice-filter-select mb-0"
            @input="(val) => $emit('update:statusFilter', val)"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </i-input>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import { booleanStatusOptions } from '@core/utils/constants'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    // categoryFilter: {
    //   type: [Number, null],
    //   default: null,
    // },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    discountTypeFilter: {
      type: String,
      default: null,
    },
    discountSegmentFilter: {
      type: String,
      default: null,
    },
    discountUsabilityFilter: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localDiscountTypeFilter: this.discountTypeFilter,
      localDiscountSegmentFilter: this.discountSegmentFilter,
      localDiscountUsabilityFilter: this.discountUsabilityFilter,
      localStatusFilter: this.statusFilter,
    }
  },
  watch: {
    discountTypeFilter(newValue) {
      this.localDiscountTypeFilter = newValue
    },
    discountSegmentFilter(newValue) {
      this.localDiscountSegmentFilter = newValue
    },
    discountUsabilityFilter(newValue) {
      this.localDiscountUsabilityFilter = newValue
    },
    statusFilter(newValue) {
      this.localStatusFilter = newValue
    },
  },
  setup() {
    const { t } = useI18nUtils()

    const discountTypeOptions = [
      { label: t('modules.coupons.inputs.discount_type_options.fixed'), value: 'fixed_discount' },
      { label: t('modules.coupons.inputs.discount_type_options.percentage'), value: 'percentage_discount' },
    ]
    const discountSegmentOptions = [
      { label: t('modules.coupons.inputs.discount_segment_options.products_price'), value: 'products_price' },
      { label: t('modules.coupons.inputs.discount_segment_options.shipping_fees'), value: 'shipping_fees' },
    ]
    const discountUsabilityOptions = [
      { label: t('modules.coupons.inputs.discount_usability_options.one_time'), value: 'one_time' },
      { label: t('modules.coupons.inputs.discount_usability_options.many_times'), value: 'many_times' },
      { label: t('modules.coupons.inputs.discount_usability_options.first_order'), value: 'first_order' },
    ]

    return {
      discountTypeOptions,
      discountSegmentOptions,
      discountUsabilityOptions,
      booleanStatusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
