export default {
  name: 'coupons',

  translatedAttributes: [
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    ...item,
    discount_value: item.discount_type === 'fixed_discount' ? item.discount_value * 100 : item.discount_value,
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    ...data,
    discount_value: data.discount_type === 'fixed_discount' ? data.discount_value / 100 : data.discount_value,
  }),

  resolveValidationErrors: errors => ({
    ...errors,
  }),
}
